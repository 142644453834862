.options {
  padding-bottom: 15px;
}

.options-header {
  font-size: 1rem;
  font-weight: normal;
}

.options-container {
  display: flex;
  flex-wrap: wrap;
}

.option-item {
  padding: 8px 15px;
  border: 1px solid #2A6EB5;
  border-radius: 25px;
  color: #2A6EB5;
  font-size: 0.9rem;
  margin: 3px;
  overflow: hidden;
}

.learning-options-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.learning-option-button {
  padding: 0.5rem;
  border-radius: 25px;
  background: transparent;
  border: 1px solid green;
  margin: 3px;
}