/*
primary colour : blue
#2A6EB5
secondary Colour:
turshiary : light green
#78A416
send button: same as tertiary colour

response chat bubble: primary blue
ul, li  border colour: primary blue

user text color: charcoal
#3E4147
header text : user text colour
*/
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;

  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-chatbot-kit-chat-input-container {
  display: none !important;
}
.react-chatbot-kit-chat-message-container {
  overflow-x: hidden !important;
}
.hideDiv{
  display: none;
}
.showMsgBox{
  display: block !important;
}
.selected{
  pointer-events:none;
}

.options {
  padding-bottom: 15px;
}

.options-header {
  text-align: center;
  font-size: 1rem;
  font-weight: normal;
}

.options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.option-item {
  padding: 8px 15px;
  border: 1px solid #2A6EB5;
  border-radius: 25px;
  color: #2A6EB5;
  font-size: 0.9rem;
  margin: 4px;
  box-shadow: inset 0px 0px 0px #2A6EB5;
  transition: all 0.5s;
  cursor: pointer;
}

.option-item:hover {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  background-color: #2A6EB5;
  color: white;
}