body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-chatbot-kit-chat-header{
  color:#3E4147 !important;
}
.react-chatbot-kit-chat-bot-message {
  background-color: #2A6EB5;
}
.react-chatbot-kit-user-chat-message{
  color:#3E4147 !important;
  text-align: right;
  margin-left: 10px;
}

.react-chatbot-kit-chat-bot-message-container{
  display: block !important;
}
.react-chatbot-kit-chat-bot-message {

   margin-left: 5px !important;
   margin-top: 4px;
 }
.react-chatbot-kit-chat-bot-message-arrow {
  width: 0;
  height: 0;
  border-top: none;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #2898ec;
  position: absolute;
  left: 6px;
  top: -5px;
  border-bottom-color: #2A6EB5;
  border-right-color: transparent !important;
}

.react-chatbot-kit-user-chat-message-container{
  display:block;
}
.react-chatbot-kit-user-avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 185.5px;
  background-color: #78A416;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
}
.react-chatbot-kit-user-chat-message-arrow {

  border-top: 8px solid #f1f1f1;
  /* border-bottom: 8px solid transparent; */
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  position: absolute;
  right: 12px;
  top: 38px;
}


.react-chatbot-kit-user-avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  background-color: #78A416;
  margin-top: 7px;
}
.react-chatbot-kit-user-avatar{
  position: relative;
}
.react-chatbot-kit-chat-container {
  position: relative;
  width: 100%;

  margin-left: auto;
  margin-right: auto;
  max-width: 915px;
}
.react-chatbot-kit-chat-inner-container{
  height:100%;
}
.react-chatbot-kit-chat-message-container{
  height:calc(100% - 95px);
}
.react-chatbot-kit-chat-bot-message {
  width:95%
}
img{
  width:100%;
}


/*Crises alert work */

.crisis-alert {
  padding: 18px 0;
  background: #000;
  position: fixed;
  width: 100%;
  height: auto;
  bottom: 0;
  transition: all .6s .3s ease-in-out;
  transform: translateX(100%);
  z-index: 2;
}
.crisis-alert__button {
  font-size: 0;
  line-height: 0;
  margin-left: -55px;
  background: #000;
  border-radius: 60px 0 0;
  bottom: 0;
  cursor: pointer;
  height: 55px;
  left: 1px;
  position: fixed;
  width: 55px;
}
.crisis-alert__button:after {
  content: '';
  position: absolute;
  background-size: contain;
  background: url(images/icons/alert-icon.svg) center center no-repeat;
  border-radius: 15px;
  height: 30px;
  left: 16px;
  top: 16px;
  width: 30px;
}
.crisis-alert__close, .crisis-alert__close span {
  position: absolute;
}
.crisis-alert__close {
  cursor: pointer;
  height: 23px;
  position: fixed;
  right: 20px;
  top: 20px;
  width: 23px;
  z-index: 3;
}
.crisis-alert__close span {
  padding: 0;
  background: #ffffff;
  height: 3px;
  left: 0;
  top: 11px;
  transform: translateY(-1px) rotate(45deg);
  width: 98%;
}
.crisis-alert__close, .crisis-alert__close span {
  position: absolute;
}
.crisis-alert__close span:last-child {
  transform: translateY(-1px) rotate(-45deg);
}
.crisis-alert__title {
  font-size: 18px;
  line-height: 30px;
  margin-top: 0;
  margin-bottom: 14px;
  font-weight: 700;
}
.crisis-alert__title, .crisis-alert__content p {
  color: #fff;
  letter-spacing: 0.01em;
}
.crisis-alert .container{
  padding: 0 36px;
}
.crisis-alert.active {
  transform: translateX(0);
  max-height: calc(100% - 60px);
  overflow-y: auto;
  background: #000;
}
@media only screen and (min-width: 768px){
  .crisis-alert__button:after {
    left: 12px;
    top: 12px;
  }
  .crisis-alert__button {
    border-radius: 30px 0 0 30px;
  }
}
/*Crises alert work */

.react-chatbot-kit-chat-bot-message a{
  color:#fff;
}